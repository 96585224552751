import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AgmCoreModule, GoogleMapsAPIWrapper } from '@agm/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { DateFormatPipe, MomentModule } from 'ngx-moment';
import { FileUploadModule } from 'ng2-file-upload/ng2-file-upload';
import { InternationalPhoneNumberModule } from 'ng-phone-number';
import { TitleCasePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { MenuComponent } from './components/menu/menu.component';
import { LoginComponent } from './components/login/login.component';
import { StoresComponent } from './components/store/stores/stores.component';
import { StorePerimeterComponent } from './components/store/store-perimeter/store-perimeter.component';
import { environment } from '../environments/environment';
import { AddStoreComponent } from './components/store/add-store/add-store.component';
import { AddStorePerimeterComponent } from './components/store/add-store-perimeter/add-store-perimeter.component';
import { MissionListComponent } from './components/mission-list/mission-list.component';
import { SlotComponent } from './components/store/slot/slot.component';
import { SetSlotModalComponent } from './components/modals/set-slot-modal/set-slot-modal.component';
import { ManageSlotModalComponent } from './components/modals/manage-slot-modal/manage-slot-modal.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { FilterPipe } from './pipes/filter.pipe';
import { I18n } from './services/custom-datepicker-i18n.service';
import { UserProfileComponent } from './components/users/user-profile/user-profile.component';
import {
  UserProfileContactInformationComponent
} from './components/users/user-profile/user-profile-contact-information/user-profile-contact-information.component';
import { UserProfileOrdersComponent } from './components/users/user-profile/user-profile-orders/user-profile-orders.component';
import {
  UserProfileSendMessageModalComponent
} from './components/modals/user-profile-send-message-modal/user-profile-send-message-modal.component';
import {
  UserProfilePerformanceComponent
} from './components/users/user-profile/user-profile-performance/user-profile-performance.component';
import { OrdersComponent } from './components/orders/orders.component';
import { TimeSlotTypePipe } from './pipes/time-slot-type.pipe';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { QuarterbackingListComponent } from './components/quarterback/quarterbacking-list/quarterbacking-list.component';
import { ClickStopPropagationDirective } from './directives/click-stop-propagation.directive';
import { GetValuesPipe } from './pipes/get-values.pipe';
import { IncidentTypeSettingComponent } from './components/lavoirmoderne/incident-type-setting/incident-type-setting.component';
import { EnumToArrayPipe } from '../lib/lib-ngx/pipes/enum-to-array.pipe';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { NewIncidentComponent } from './components/incident/new-incident/new-incident.component';
import { IncidentListComponent } from './components/incident/incident-list/incident-list.component';
import { IncidentDetailsComponent } from './components/incident/incident-details/incident-details.component';
import { HttpErrorInterceptorService } from '../lib/lib-ngx/services/http-error-interceptor.service';
import { ArchivedIncidentComponent } from './components/incident/archived-incident/archived-incident.component';
import { LavandierIncidentComponent } from './components/incident/lavandier-incident/lavandier-incident.component';
import { ArticleFamilyComponent } from './components/article/article-family/article-family.component';
import { ArticleSettingComponent } from './components/article/article-setting/article-setting.component';
import { ArticleListComponent } from './components/article/article-list/article-list.component';
import { NewArticleComponent } from './components/article/new-article/new-article.component';
import { ArticlePricesComponent } from './components/article/article-prices/article-prices.component';
import { ArticleDetailsComponent } from './components/article/article-details/article-details.component';
import { ArticleOptionTypeComponent } from './components/article/article-setting/article-option-type/article-option-type.component';
import { ArticleOptionComponent } from './components/article/article-setting/article-option/article-option.component';
import { LmImagePipe } from './pipes/lm-image.pipe';
import { LmUploadPipe } from './pipes/lm-upload.pipe';
import { ImagePreviewDirective } from './directives/image-preview.directive';
import { LavandierListComponent } from './components/lavandier/lavandier-list/lavandier-list.component';
import { LavandierDetailsComponent } from './components/lavandier/lavandier-details/lavandier-details.component';
import { NewLavandierComponent } from './components/lavandier/new-lavandier/new-lavandier.component';
import { UserTypeSettingComponent } from './components/lavoirmoderne/user-type-setting/user-type-setting.component';
import { UserTypeEditComponent } from './components/lavoirmoderne/user-type-edit/user-type-edit.component';
import { ScanTrackingSheetComponent } from './components/quarterback/scan-tracking-sheet/scan-tracking-sheet.component';
import { EnumNumberValueToArrayPipe } from './pipes/enum-number-value-to-array.pipe';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { OrderStoredListComponent } from './components/quarterback/order-stored-list/order-stored-list.component';
import { ImagePreviewModalComponent } from './components/modals/image-preview-modal/image-preview-modal.component';
import { QuarterbackOrderDetailsComponent } from './components/quarterback/quarterback-order-details/quarterback-order-details.component';
import { QuarterbackingDetailsComponent } from './components/quarterback/quarterbacking-details/quarterbacking-details.component';
import { ConfigService } from '../lib/lib-ngx/services/config.service';
import { DiscountCodeListComponent } from './components/users/discount-code-list/discount-code-list.component';
import { DiscountCodeAddComponent } from './components/users/discount-code-add/discount-code-add.component';
import { TabIncidentComponent } from './components/incident/tab-incident/tab-incident.component';
import { RoleListComponent } from './components/lavandier/role-list/role-list.component';
import { RoleDetailsComponent } from './components/lavandier/role-details/role-details.component';
import { RoleAddComponent } from './components/lavandier/role-add/role-add.component';
import {
  ScanTrackingSheetDetailsComponent
} from './components/quarterback/scan-tracking-sheet-details/scan-tracking-sheet-details.component';
import {
  UserDataVerificationModalComponent
} from './components/modals/user-data-verification-modal/user-data-verification-modal.component';
import { ManageAddressModalComponent } from './components/modals/manage-address-modal/manage-address-modal.component';
import { UserCreditComponent } from './components/users/user-profile/user-credit/user-credit.component';
import { CalendarComponent } from './components/store/calendar/calendar.component';
import { EditCalendarDayModalComponent } from './components/modals/edit-calendar-day-modal/edit-calendar-day-modal.component';
import { EditAccountComponent } from './components/account/edit-account/edit-account.component';
import { EditUserComponent } from './components/user/edit-user/edit-user.component';
import { AddPaymentModalComponent } from './components/modals/add-payment-modal/add-payment-modal.component';
import { OrderWantedDeliveryComponent } from './components/order/order-wanted-delivery/order-wanted-delivery.component';
import {
  AddOrderWantedDeliveryModalComponent
} from './components/modals/add-order-wanted-delivery-modal/add-order-wanted-delivery-modal.component';
import { OrderStoredModalComponent } from './components/modals/order-stored-modal/order-stored-modal.component';
import { EventConfigEditModalComponent } from './components/modals/event-config-edit-modal/event-config-edit-modal.component';
import { ShippingPriceEditModalComponent } from './components/modals/shipping-price-edit-modal/shipping-price-edit-modal.component';
import { UserIncidentsComponent } from './components/users/user-profile/user-incidents/user-incidents.component';
import {
  AddOrderTransportCustomModalComponent
} from './components/modals/add-order-transport-custom-modal/add-order-transport-custom-modal.component';
import { QuarterbackComponent } from './components/quarterback/quarterback/quarterback.component';
import { QuarterbackTransferModalComponent } from './components/modals/quarterback-transfer-modal/quarterback-transfer-modal.component';
import { HasFlagsPipe } from '../lib/lib-ngx/pipes/has-flags.pipe';
import { ScheduleOrderComponent } from './components/common/schedule-order/schedule-order.component';
import { APP_TOKEN, appInitializerFn } from '../lib/lib-ngx/services/AppInitializerFn';
import { VersionService } from '../lib/lib-ngx/services/version.service';
import { HttpInterceptorVersionService } from '../lib/lib-ngx/services/http-interceptor-version.service';
import { EditShopHoursModalComponent } from './components/modals/edit-shop-hours-modal/edit-shop-hours-modal.component';
import { QRCodeAPIKeyModalComponent } from './components/modals/qrcode-apikey-modal/qrcode-apikey-modal.component';
import { OrderTraceabilityComponent } from './components/common/order-traceability/order-traceability.component';
import {
  EditTransportScheduleModalComponent
} from './components/modals/edit-transport-schedule-modal/edit-transport-schedule-modal.component';

declare function require(moduleName: string): any;

// todo change when upgrading angular version https://stackoverflow.com/a/48869478
export const npm = require('../../package.json');

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    LoginComponent,
    StoresComponent,
    StorePerimeterComponent,
    AddStoreComponent,
    AddStorePerimeterComponent,
    MissionListComponent,
    SlotComponent,
    SetSlotModalComponent,
    ManageSlotModalComponent,
    UserListComponent,
    FilterPipe,
    UserProfileComponent,
    UserProfileContactInformationComponent,
    UserProfileOrdersComponent,
    UserProfileSendMessageModalComponent,
    UserProfilePerformanceComponent,
    OrdersComponent,
    TimeSlotTypePipe,
    OrderDetailsComponent,
    QuarterbackingListComponent,
    ArticleDetailsComponent,
    ClickStopPropagationDirective,
    GetValuesPipe,
    IncidentTypeSettingComponent,
    EnumToArrayPipe,
    SideMenuComponent,
    NewIncidentComponent,
    IncidentListComponent,
    IncidentDetailsComponent,
    ArchivedIncidentComponent,
    LavandierIncidentComponent,
    ArticleFamilyComponent,
    ArticleSettingComponent,
    ArticleListComponent,
    NewArticleComponent,
    ArticleOptionTypeComponent,
    ArticleOptionComponent,
    LmImagePipe,
    LmUploadPipe,
    ImagePreviewDirective,
    LavandierListComponent,
    LavandierDetailsComponent,
    NewLavandierComponent,
    ArticlePricesComponent,
    UserTypeSettingComponent,
    UserTypeEditComponent,
    ScanTrackingSheetComponent,
    EnumNumberValueToArrayPipe,
    ConfirmationModalComponent,
    OrderStoredListComponent,
    ImagePreviewModalComponent,
    QuarterbackOrderDetailsComponent,
    QuarterbackingDetailsComponent,
    DiscountCodeListComponent,
    DiscountCodeAddComponent,
    TabIncidentComponent,
    RoleListComponent,
    RoleDetailsComponent,
    RoleAddComponent,
    ScanTrackingSheetDetailsComponent,
    UserDataVerificationModalComponent,
    ManageAddressModalComponent,
    UserCreditComponent,
    CalendarComponent,
    EditCalendarDayModalComponent,
    EditAccountComponent,
    EditUserComponent,
    AddPaymentModalComponent,
    OrderWantedDeliveryComponent,
    AddOrderWantedDeliveryModalComponent,
    OrderStoredModalComponent,
    EventConfigEditModalComponent,
    ShippingPriceEditModalComponent,
    UserIncidentsComponent,
    AddOrderTransportCustomModalComponent,
    QuarterbackComponent,
    QuarterbackTransferModalComponent,
    HasFlagsPipe,
    ScheduleOrderComponent,
    EditShopHoursModalComponent,
    QRCodeAPIKeyModalComponent,
    OrderTraceabilityComponent,
    EditTransportScheduleModalComponent,
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: environment.api_key_google,
      libraries: ['places']
    }),
    NgxDatatableModule,
    NgHttpLoaderModule,
    NgxDnDModule,
    MomentModule,
    FileUploadModule,
    InternationalPhoneNumberModule,
  ],
  providers: [
    I18n,
    GoogleMapsAPIWrapper,
    {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true},
    DateFormatPipe,
    TitleCasePipe,
    {provide: APP_TOKEN, useValue: {name: npm.name, version: npm.version}},
    {provide: APP_INITIALIZER, useFactory: appInitializerFn, multi: true, deps: [ConfigService, VersionService, APP_TOKEN]},
    {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorVersionService, multi: true},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SetSlotModalComponent,
    ManageSlotModalComponent,
    UserProfileSendMessageModalComponent,
    UserProfilePerformanceComponent,
    ConfirmationModalComponent,
    ImagePreviewModalComponent,
    UserDataVerificationModalComponent,
    ManageAddressModalComponent,
    EditCalendarDayModalComponent,
    AddPaymentModalComponent,
    AddOrderWantedDeliveryModalComponent,
    OrderStoredModalComponent,
    EventConfigEditModalComponent,
    ShippingPriceEditModalComponent,
    AddOrderTransportCustomModalComponent,
    QuarterbackTransferModalComponent,
    EditShopHoursModalComponent,
    QRCodeAPIKeyModalComponent,
    EditTransportScheduleModalComponent,
  ]
})
export class AppModule {
}
