import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-article-details',
  templateUrl: './article-details.component.html',
  styleUrls: ['./article-details.component.scss']
})
export class ArticleDetailsComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public articleId;
  public articleDetails = null;
  public articleFamilyMap = new Map();

  constructor(
    private route: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
  ) {
  }

  ngOnInit() {
    this.route.params.subscribe(param => {
      this.articleId = param.id;
      this.resetData();
      this.loadData();
    });
  }

  resetData() {
    this.articleDetails = null;
    this.articleFamilyMap = new Map();
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getArticle(this.articleId),
      this.apiLavandierService.getArticleFamilyList(),
    ])
      .pipe(
        map(([articleDetails, articleFamilyList]: [Object, Object[]]) => {
          return [
            articleDetails,
            ListToMap.convert(articleFamilyList),
          ];
        })
      )
      .subscribe(([articleDetails, articleFamilyMap]: [Object, Map<number, Object>]) => {
        this.articleDetails = articleDetails;
        this.initArticleDetailsMaps();
        this.articleFamilyMap = articleFamilyMap;
      });
  }

  initArticleDetailsMaps() {
    this.articleDetails['articlePriceMap'] = new Map();
    this.articleDetails.articlePrices.forEach((articlePrice) => {
      if (!this.articleDetails.articlePriceMap.get(articlePrice.userTypeId)) {
        this.articleDetails.articlePriceMap.set(articlePrice.userTypeId, articlePrice);
      }
    });

    this.articleDetails['articleOptionPriceMap'] = new Map();
    this.articleDetails.articleOptionPrices.forEach((articleOptionPrice) => {
      if (!this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId)) {
        this.articleDetails.articleOptionPriceMap.set(articleOptionPrice.userTypeId, new Map());
      }
      if (!this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId).get(articleOptionPrice.articleOptionId)) {
        this.articleDetails.articleOptionPriceMap.get(articleOptionPrice.userTypeId).set(articleOptionPrice.articleOptionId, articleOptionPrice);
      }
    });
  }
}
