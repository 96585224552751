import { Component, Input, OnInit } from '@angular/core';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';
import { ImagePreviewModalComponent } from '../../modals/image-preview-modal/image-preview-modal.component';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';

enum TraceabilityFilters {
  PICKUP,
  INVOICE,
  PTL,
  SHIPPING,
  STORAGE,
  TRANSFER,
  DELIVERY,
}

@Component({
  selector: 'lm-order-traceability',
  templateUrl: './order-traceability.component.html',
  styleUrls: ['./order-traceability.component.scss']
})
export class OrderTraceabilityComponent implements OnInit {
  public TraceabilityFilters = TraceabilityFilters;

  public columnsLabel = [
    {value: TraceabilityFilters.PICKUP, label: 'Enlèvement', class: 'lm-red-btn'},
    {value: TraceabilityFilters.INVOICE, label: 'Tri/Facturation', class: 'lm-orange-btn'},
    {value: TraceabilityFilters.PTL, label: 'PTL', class: 'lm-grey-btn'},
    {value: TraceabilityFilters.SHIPPING, label: 'Expédition', class: 'lm-blue-btn'},
    {value: TraceabilityFilters.STORAGE, label: 'Rangement Hub', class: 'lm-aqua-btn'},
    {value: TraceabilityFilters.TRANSFER, label: 'Transfert ', class: 'lm-cyan-btn'},
    {value: TraceabilityFilters.DELIVERY, label: 'Livraison', class: 'lm-green-btn'},
  ];

  @Input() public order = null;
  @Input() public lavandierMap = new Map();
  @Input() public storageMap = new Map();

  public selectedFilter = TraceabilityFilters.PICKUP;

  constructor(
    private datePipe: DatePipe,
    private modalService: NgbModal,
  ) {
  }

  ngOnInit() {
  }

  getTraceabilityRows() {
    const rows = [];
    if (this.selectedFilter === TraceabilityFilters.PICKUP) {
      const id = this.order.id;
      const pickupOrderTransportFilteredList = this.order.orderTransports.filter(row => row.type === OrderTransportType.PICKUP);
      const pickupOrderTransport = pickupOrderTransportFilteredList.length ? pickupOrderTransportFilteredList[pickupOrderTransportFilteredList.length - 1] : null;

      this.order.orderBags.forEach(bag => {
        rows.push({
          id,
          pickLavandier: pickupOrderTransport ? this.getLanvandierFullNameById(pickupOrderTransport.lavandierId) : null,
          pickDate: pickupOrderTransport ? this.datePipe.transform(pickupOrderTransport.doneAt, 'dd/MM/yyyy à HH:mm') : null,
          ref: bag.ref,
          bagId: bag.id,
          creation: bag.creation,
          creationDate: this.datePipe.transform(bag.createdAt, 'dd/MM/yyyy à HH:mm'),
          edit: bag.nbEdit > 0 ? 'oui' : 'non',
          type: bag.type,
          checkBy: this.getLanvandierFullNameById(bag.checkByLavandierId),
          checkDate: this.datePipe.transform(bag.checkAt, 'dd/MM/yyyy à HH:mm'),
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.INVOICE) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            rows.push({
              articleId: article.id,
              ref: tracking.ref,
              name: article.name,
              lavandier: this.getLanvandierFullNameById(article.lavandierId),
              date: this.datePipe.transform(tracking.createdAt, 'dd/MM/yyyy à HH:mm'),
              uploadId: article.uploadId,
            });
          });
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.PTL) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            rows.push({
              articleId: article.id,
              refArticle: tracking.ref,
              ref: tracking.storageId && this.storageMap.get(tracking.storageId) ? this.storageMap.get(tracking.storageId).ref : null,
              nbScan: tracking.nbScan,
              lavandier: this.getLanvandierFullNameById(tracking.tidiedByLavandierId),
              date: this.datePipe.transform(tracking.tidiedAt, 'dd/MM/yyyy à HH:mm'),
              ts: tracking.trackingSheetId,
            });
          });
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.SHIPPING) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            rows.push({
              articleId: article.id,
              refArticle: tracking.ref,
              ts: tracking.trackingSheetId,
              factoryMissing: tracking.factoryMissing,
              lavandier: this.getLanvandierFullNameById(tracking.factoryByLavandierId),
              date: this.datePipe.transform(tracking.factoryAt, 'dd/MM/yyyy à HH:mm'),
            });
          });
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.STORAGE) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            const missing = tracking.tsMissing;
            if (tracking.trackingSheet) {
              tracking.trackingSheet.trackingSheetStorageList.forEach(tss => {
                rows.push({
                  articleId: article.id,
                  refArticle: tracking.ref,
                  ts: tracking.trackingSheetId,
                  missing,
                  ref: tss.shopStorage ? tss.shopStorage.ref : null,
                  lavandier: this.getLanvandierFullNameById(tss.tidiedByLavandierId),
                  date: this.datePipe.transform(tss.tidiedAt, 'dd/MM/yyyy à HH:mm')
                });
              });
            }
          });
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.TRANSFER) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            if (tracking.trackingSheet) {
              tracking.trackingSheet.trackingSheetDeliveryList.forEach(tsd => {
                if (!rows.some(row => row.ts === tracking.trackingSheetId)) {
                  rows.push({
                    ts: tracking.trackingSheetId,
                    orderTransportId: tsd.orderTransportId,
                    transferBy: tsd.transferById ? this.getLanvandierFullNameById(tsd.transferById) : null,
                    createdAt: this.datePipe.transform(tsd.createdAt, 'dd/MM/yyyy à HH:mm'),
                    missing: tsd.missing,
                    orderTransportDoneBy: tsd.orderTransport.lavandierId ? this.getLanvandierFullNameById(tsd.orderTransport.lavandierId) : null,
                    orderTransportDoneAt: tsd.orderTransport.doneAt ? this.datePipe.transform(tsd.orderTransport.doneAt, 'dd/MM/yyyy à HH:mm') : null,
                    missingFactoryReceived: tsd.missingFactoryReceived,
                    missingFactoryReceivedBy: tsd.missingFactoryReceivedById ? this.getLanvandierFullNameById(tsd.missingFactoryReceivedById) : null,
                    missingFactoryReceivedAt: this.datePipe.transform(tsd.missingFactoryReceivedAt, 'dd/MM/yyyy à HH:mm'),
                  });
                }
              });
            }
          });
        });
      });
    } else if (this.selectedFilter === TraceabilityFilters.DELIVERY) {
      this.order.orderBags.forEach(bag => {
        bag.orderArticles.forEach(article => {
          article.orderArticleTrackingList.forEach(tracking => {
            if (tracking.trackingSheet) {
              tracking.trackingSheet.trackingSheetStorageList.forEach(tss => {
                const deliveryOrderTransport = this.order.orderTransports.find(row => row.id === tss.orderTransportId);
                rows.push({
                  articleId: article.id,
                  refArticle: tracking.ref,
                  ts: tracking.trackingSheetId,
                  missing: tss.missing,
                  qb: this.getLanvandierFullNameById(tss.preparedByLavandierId),
                  qbDate: this.datePipe.transform(tss.preparedAt, 'dd/MM/yyyy à HH:mm'),
                  missingAtDelivery: tss.missingAtDelivery,
                  lavandier: deliveryOrderTransport ? this.getLanvandierFullNameById(deliveryOrderTransport.lavandierId) : null,
                  date: deliveryOrderTransport ? this.datePipe.transform(deliveryOrderTransport.doneAt, 'dd/MM/yyyy à HH:mm') : null,
                });
              });
            }
          });
        });
      });
    }
    return rows;
  }

  getTraceabilityColumns() {
    const columns = [];
    if (this.selectedFilter === TraceabilityFilters.PICKUP) {
      columns.push(
        {prop: 'id', name: 'N° commande'},
        {prop: 'pickLavandier', name: 'Lavandier Enlèvement'},
        {prop: 'pickDate', name: 'Date Enlèvement'},
        {prop: 'ref', name: 'Ref sac'},
        {prop: 'bagId', name: 'Id sac'},
        {prop: 'type', name: 'Type'},
        {prop: 'creation', name: 'Creation'},
        {prop: 'creationDate', name: 'Date création'},
        {prop: 'edit', name: 'Sac modifié ?'},
        {prop: 'checkBy', name: 'Check TL'},
        {prop: 'checkDate', name: 'Date Check Collecte'});
    } else if (this.selectedFilter === TraceabilityFilters.PTL) {
      columns.push(
        {prop: 'articleId', name: 'Id article'},
        {prop: 'refArticle', name: 'N° article'},
        {prop: 'ref', name: 'Nom emplacement PTL'},
        {prop: 'nbScan', name: 'Nombre passage PTL '},
        {prop: 'lavandier', name: 'Lavandier PTL'},
        {prop: 'date', name: 'Date PTL'},
        {prop: 'ts', name: 'N° TS'},
      );
    } else if (this.selectedFilter === TraceabilityFilters.SHIPPING) {
      columns.push(
        {prop: 'articleId', name: 'Id article'},
        {prop: 'refArticle', name: 'N° article'},
        {prop: 'ts', name: 'N° TS'},
        {prop: 'factoryMissing', name: 'Manquant Expédition'},
        {prop: 'lavandier', name: 'Lavandier expédition'},
        {prop: 'date', name: 'Expédié le'},
      );
    } else if (this.selectedFilter === TraceabilityFilters.STORAGE) {
      columns.push(
        {prop: 'articleId', name: 'Id article'},
        {prop: 'refArticle', name: 'N° article'},
        {prop: 'ts', name: 'N° TS'},
        {prop: 'missing', name: 'Manquant Rangement'},
        {prop: 'ref', name: 'Emplacement'},
        {prop: 'lavandier', name: 'TL rangement LMP'},
        {prop: 'date', name: 'Date rangement'},
      );
    } else if (this.selectedFilter === TraceabilityFilters.TRANSFER) {
      columns.push(
        {prop: 'ts', name: 'N° TS'},
        {prop: 'orderTransportId', name: 'N° order Transport'},
        {prop: 'transferBy', name: 'Transfert par'},
        {prop: 'createdAt', name: ' Transfert le'},
        {prop: 'missing', name: ' Manquant a la livraison'},
        {prop: 'orderTransportDoneBy', name: ' Livraison par'},
        {prop: 'orderTransportDoneAt', name: ' Livraison le'},
        {prop: 'missingFactoryReceived', name: 'Manquante à la réception'},
        {prop: 'missingFactoryReceivedBy', name: 'Réception par'},
        {prop: 'missingFactoryReceivedAt', name: 'Réception le'},
      );
    } else if (this.selectedFilter === TraceabilityFilters.DELIVERY) {
      columns.push(
        {prop: 'articleId', name: 'Id article'},
        {prop: 'refArticle', name: 'N° article'},
        {prop: 'ts', name: 'N° TS'},
        {prop: 'missing', name: ' Manquant QB'},
        {prop: 'qb', name: 'Quarterback'},
        {prop: 'qbDate', name: 'Date QB'},
        {prop: 'missingAtDelivery', name: 'Manquant à la Livraison'},
        {prop: 'lavandier', name: 'Lavandier Livraison'},
        {prop: 'date', name: 'Date Livraison'},
      );
    }
    return columns;
  }

  getLanvandierFullNameById(id: number) {
    const lavandier = this.lavandierMap.get(id);
    const lavandierFullName = lavandier ? lavandier.user.firstName + ' ' + lavandier.user.lastName : null;
    return new TitleCasePipe().transform(lavandierFullName);
  }

  openImagePreviewModal(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      const data = event.row;
      if (data.uploadId) {
        const imagePreviewModal = this.modalService.open(ImagePreviewModalComponent, {size: 'lg'});
        imagePreviewModal.componentInstance.imageList = [data];
        imagePreviewModal.componentInstance.selectedImage = data;
      } else {
        const confirmationModal = this.modalService.open(ConfirmationModalComponent, {size: 'lg'});
        confirmationModal.componentInstance.confirmationDesc = 'Pas de photo disponible';
        confirmationModal.componentInstance.cancelButtonText = 'Fermer';
      }
    }
  }
}
