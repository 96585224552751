import { ErrorMessage } from '../../lib-shared/ErrorMessage';

export const ErrorMessageMap = new Map([
  [ErrorMessage.ORDER_ARTICLETRACKING_REF_NOTFOUND, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_PIN_NOTFOUND, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_ALREADY_TIDIED, `Article déjà scanné`],
  [ErrorMessage.NO_STORAGE_AVAILABLE, `Aucun emplacement disponible`],
  [ErrorMessage.DELIVERY_ALREADY_ASKED, `Livraison déjà programmée pour cette commande`],
  [ErrorMessage.SLOT_NOT_AVAILABLE_DATE, `La date du créneau horaire n'est plus disponible`],
  [ErrorMessage.SLOT_NOT_AVAILABLE_TIME, `L'heure du créneau horaire n'est plus disponible`],
  [ErrorMessage.NO_TRANSPORT_TO_CANCEL, ``],
  [ErrorMessage.PICKUPINSHOP_IS_NOT_CANCELABLE, ``],
  [ErrorMessage.TOO_LATE_TO_CANCEL_TRANSPORT, ``],
  [ErrorMessage.INCORRECT_CREDENTIAL, ``],
  [ErrorMessage.INVALID_TOKEN, ``],
  [ErrorMessage.ACCOUNT_DISABLED, ``],
  [ErrorMessage.ORDER_ALREADY_PAID, `Votre commande a déjà été payée`],
  [ErrorMessage.ORDER_ALREADY_FINISH, ``],
  [ErrorMessage.ORDER_NOT_PAYABLE, `Nos équipes effectuent actuellement une correction sur la facturation de votre commande, merci de réitérer votre demande ultérieurement.\n` +
  `Un nouvel email de confirmation de commande vous sera à nouveau adressé prochainement.`],
  [ErrorMessage.NOT_A_DELIVERABLE_ORDER, `L'état de votre commande ne lui permet pas d'être livrée`],
  [ErrorMessage.EMAIL_ALREADY_USED, ``],
  [ErrorMessage.ORDERTRANSPORT_NOT_QUARTERBACKABLE, `Livraison non quarterbackable.`],
  [ErrorMessage.NOT_ALLOWED, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_WRONG_FLUX, `Mauvais flux`],
  [ErrorMessage.NOT_MANAGED_ADDRESS, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_IS_NOT_UNTIDIED, ``],
  [ErrorMessage.ORDERBAG_ALREADY_FINISH, ``],
  [ErrorMessage.INVOICE_NOT_AVAILABLE, `Facture non disponible.`],
  [ErrorMessage.ORDERTRANSPORT_NOT_REOPENABLE, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_REF_ALREADY_EXIST, ``],
  [ErrorMessage.INCORRECT_VALUE, ``],
  [ErrorMessage.WRONG_PHONE_CODE, 'Code de validation erroné'],
  [ErrorMessage.TRACKINGSHEET_NOT_TIDIABLE, `Fiche de suivi non rangeable`],
  [ErrorMessage.INCORRECT_ENDPOINT, ``],
  [ErrorMessage.ORDER_PAYMENT_PROCESSING, `Le paiement de votre commande est déjà en cours de traitement.`],
  [ErrorMessage.TRACKINGSHEET_NOT_FACTORY_CHECKED, `Fiche de suivi non contrôlée`],
  [ErrorMessage.ORDER_NOT_AFTERSALE, `Cette commande n'est pas une commande SAV.`],
  [ErrorMessage.ORDER_NOT_TEMPORARY, ``],
  [ErrorMessage.ORDERBAG_REF_ALREADY_EXIST, ``],
  [ErrorMessage.NO_DELIVERYMULTIPLE_AVAILABLE, ``],
  [ErrorMessage.ORDER_NOT_TRANSFERABLE, ``],
  [ErrorMessage.ORDERBAG_ALREADY_CHECKED, ``],
  [ErrorMessage.ORDER_NOT_PICKUP_MULTIPLE, ``],
  [ErrorMessage.ORDERBAG_NOT_CHECKED, ``],
  [ErrorMessage.TRACKINGSHEET_NOT_TIDIED, ``],
  [ErrorMessage.ORDER_NOT_PAID, `Commande non payée.`],
  [ErrorMessage.INCIDENT_ALREADY_FINISH, ``],
  [ErrorMessage.ORDER_ARTICLETRACKING_ORDER_INCIDENT, `❗❗ Article SAV ❗❗`],
  [ErrorMessage.DELIVERY_ADDRESS_NOT_IN_THE_SAME_PERIMETER, `Adresse de livraison différente de l'adresse de collecte.`],
  [ErrorMessage.ORDER_ARTICLETRACKING_ALREADY_FACTORY, `Article déjà contrôlé`],
  [ErrorMessage.ORDERBAG_IS_LAST_NOT_FINISH_OF_EMPTY_ORDER, `Impossible de clôturer le dernier sac d'une commande vide.`],
]);
