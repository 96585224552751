import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDateParserFormatter, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader } from 'ng2-file-upload';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import {
  IOrderAdmin,
  IIncidentComment,
  IIncidentImages,
  IIncidentStatus,
} from '../../../../lib/lib-ngx/web-services/api-lavandier.service.type';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ImagePreviewModalComponent } from '../../modals/image-preview-modal/image-preview-modal.component';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { CreditType } from '../../../../lib/lib-shared/types/CreditType';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { UserTypeOrderType } from '../../../../lib/lib-shared/types/UserTypeOrderType';
import { IncidentStatusStatus } from '../../../../lib/lib-shared/types/IncidentStatusStatus';
import { PRICEMULTIPLIER, VATType } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-incident-details',
  templateUrl: './incident-details.component.html',
  styleUrls: ['./incident-details.component.scss']
})
export class IncidentDetailsComponent implements OnInit {
  public CreditType = CreditType;
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public UserTypeOrderType = UserTypeOrderType;
  public VATType = VATType;

  public schedulePickUp = false;
  public creditForm: FormGroup;
  public orderLinesForm: FormGroup;

  public incidentDetails;
  public incidentId = '';
  public incidentTypeMap = new Map();
  public lavandierMap = new Map();
  public shopMap = new Map();
  public uploader: FileUploader = new FileUploader({
    allowedFileType: ['image']
  });
  public comment = new FormControl('', Validators.required);
  public filePreviewPathList: SafeUrl[];

  public userAddressList = [];
  public eventList = [];
  public user = null;

  constructor(
    private route: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.resetData();
    this.buildForm();
    this.route.params.subscribe((params) => {
      this.incidentId = params.id;
      this.loadData();
    });
  }

  resetData() {
    this.incidentDetails = null;
    this.incidentTypeMap = new Map();
    this.lavandierMap = new Map();
    this.eventList = [];
  }

  buildForm() {
    this.creditForm = this.fb.group({
      amount: [null, Validators.required],
      type: [CreditType.MINUS, Validators.required],
      description: ['', Validators.required],
      endedAt: [this.ngbDateParserFormatter.parse(moment().add(2, 'months').format()), Validators.required],
    });

    this.orderLinesForm = this.fb.group({
      price: [null, Validators.required],
      vat: ['', Validators.required],
      description: ['', Validators.required],
      type: ['+', Validators.required],
    });
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getIncidentDetails(this.incidentId),
      this.apiLavandierService.getIncidentTypes(),
      this.apiLavandierService.getLavandiers(),
      this.apiLavandierService.getShops(),
    ])
      .pipe(
        map(([incidentDetails, incidentTypesList, lavandierList, shopList]: [Object, Object[], any, Object[]]) => {
          return [
            incidentDetails,
            ListToMap.convert(incidentTypesList),
            ListToMap.convert(lavandierList.rows),
            ListToMap.convert(shopList),
          ];
        })
      )
      .subscribe(([incidentDetails, incidentTypeMap, lavandierMap, shopMap]: [Object, Map<number, Object>, Map<number, Object>, Map<number, Object>]) => {
        this.incidentDetails = incidentDetails;
        this.incidentTypeMap = incidentTypeMap;
        this.lavandierMap = lavandierMap;
        this.shopMap = shopMap;

        this.apiLavandierService.getIncidentList({orderId: this.incidentDetails.order.id})
          .subscribe((eventList: any) => this.eventList = eventList.rows);

        this.apiLavandierService.getUserAddressList(this.incidentDetails.order.userId)
          .subscribe((addressList: Object[]) => {
            this.userAddressList = addressList;
          });

        this.apiLavandierService.getUser(this.incidentDetails.order.userId)
          .subscribe(user => this.user = user);
      });
  }

  /**
   * Method called when images are added
   * this method will add images url in filePreviewPath
   */
  onAddImage() {
    this.filePreviewPathList = [];
    this.uploader.queue.forEach((file) => {
      this.filePreviewPathList.push(this.sanitizer.bypassSecurityTrustUrl((window.URL.createObjectURL(file._file))));
    });
  }

  /**
   * Method called to add a new comment
   */
  onAddComment() {
    const incidentComment: IIncidentComment = {
      incidentId: this.incidentDetails.id,
      comment: this.comment.value
    };
    this.apiLavandierService.postIncidentComment(incidentComment)
      .subscribe((data: Object) => {
        if (this.uploader.queue.length) {
          this.onAddIncidentImages(data);
          return;
        }
        this.ngOnInit();
      });
  }

  /**
   * Method called after a success on adding a new incident
   * This method will post images for the new Incident
   * @param incidentComment
   */
  onAddIncidentImages(incidentComment) {
    const incidentImage: IIncidentImages = {
      incidentCommentId: incidentComment.id,
      imageList: []
    };
    this.uploader.queue.forEach((item) => {
      incidentImage.imageList.push(item._file);
    });
    this.apiLavandierService.postIncidentImages(incidentImage).subscribe(() => this.ngOnInit());
  }

  /**
   * Method called when user click on cancel to add a new comment
   * It'll reset the form and clear all images in upload and filePreviewPath
   */
  resetComment() {
    this.comment.reset();
    this.uploader.clearQueue();
    this.filePreviewPathList = [];
  }

  /**
   * Method called to create a new after sale pick-up
   */
  onAddPickUp(orderTransportData) {
    const orderAdmin: IOrderAdmin = {
      userId: this.incidentDetails.order.userId,
      incidentId: this.incidentDetails.id,
      discountCodeCode: null,
      stripeCardId: null,
      addresses: orderTransportData.addressList,
    };

    this.apiLavandierService.postOrder(orderAdmin)
      .subscribe((data) => {
          if (!this.errorService.manageError(data)) {
            const confirmModal = this.modalService.open(ConfirmationModalComponent);
            confirmModal.componentInstance.confirmationDesc = 'Confirmation de la création d\'une commande SAV pour l\'événement numéro ' +
              this.incidentDetails.id;
            confirmModal.componentInstance.validateButtonText = 'Ok';
            this.ngOnInit();
          }
        }
      );
  }

  /**
   * Method called to modify incident status
   */
  onIncidentStatus() {
    const incidentStatus: IIncidentStatus = {
      incidentId: this.incidentDetails.id,
      incidentStatus: this.incidentDetails.finish ? IncidentStatusStatus.OPEN : IncidentStatusStatus.CLOSE
    };

    this.apiLavandierService.postIncidentStatus(incidentStatus).subscribe(() => this.ngOnInit());
  }

  imagePreview(imageList, selectedImage) {
    const imagePreviewModal = this.modalService.open(ImagePreviewModalComponent, {size: 'lg'});
    imagePreviewModal.componentInstance.imageList = imageList;
    imagePreviewModal.componentInstance.selectedImage = selectedImage;
  }

  onAddCredit() {
    this.apiLavandierService.postCredit({
      userId: this.incidentDetails.order.user.id,
      incidentId: this.incidentDetails.id,
      type: this.creditForm.value.type,
      description: this.creditForm.value.description,
      amount: this.creditForm.value.amount * PRICEMULTIPLIER,
      endedAt: this.ngbDateParserFormatter.format(this.creditForm.value.endedAt),
    }).subscribe(() => this.ngOnInit());
  }

  onAddOrderLine() {
    const orderLinePrice = Math.abs(this.orderLinesForm.value.price) * PRICEMULTIPLIER;

    this.apiLavandierService.postIncidentIdOrderline(this.incidentDetails.id, {
      description: this.orderLinesForm.value.description,
      price: this.orderLinesForm.value.type === '+' ? +orderLinePrice : -orderLinePrice,
      vat: this.orderLinesForm.value.vat,
    })
      .subscribe((data) => {
        if (!this.errorService.manageError(data)) {
          this.ngOnInit();
        }
      });
  }
}
