export enum PermissionName {
  ACCOUNT_CREATE = 'ACCOUNT_CREATE',
  ACCOUNT_EDIT = 'ACCOUNT_EDIT',
  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',

  ADDRESS_CREATE = 'ADDRESS_CREATE',
  ADDRESS_DEFAULT = 'ADDRESS_DEFAULT',
  ADDRESS_EDIT = 'ADDRESS_EDIT',
  ADDRESS_DELETE = 'ADDRESS_DELETE',

  ARTICLE_FAMILY_CREATE = 'ARTICLE_FAMILY_CREATE',
  ARTICLE_OPTION_PRICE_CREATE = 'ARTICLE_OPTION_PRICE_CREATE',
  ARTICLE_OPTION_TYPE_CREATE = 'ARTICLE_OPTION_TYPE_CREATE',
  ARTICLE_OPTION_CREATE = 'ARTICLE_OPTION_CREATE',
  ARTICLE_PRICE_CREATE = 'ARTICLE_PRICE_CREATE',
  ARTICLE_RANGE_CREATE = 'ARTICLE_RANGE_CREATE',
  ARTICLE_CREATE = 'ARTICLE_CREATE',
  ARTICLE_FAMILY_EDIT = 'ARTICLE_FAMILY_EDIT',
  ARTICLE_OPTION_PRICE_EDIT = 'ARTICLE_OPTION_PRICE_EDIT',
  ARTICLE_OPTION_TYPE_EDIT = 'ARTICLE_OPTION_TYPE_EDIT',
  ARTICLE_OPTION_EDIT = 'ARTICLE_OPTION_EDIT',
  ARTICLE_PRICE_EDIT = 'ARTICLE_PRICE_EDIT',
  ARTICLE_RANGE_EDIT = 'ARTICLE_RANGE_EDIT',
  ARTICLE_EDIT = 'ARTICLE_EDIT',
  ARTICLE_FAMILY_LIST = 'ARTICLE_FAMILY_LIST',
  ARTICLE_OPTION_PUBLIC_LIST = 'ARTICLE_OPTION_PUBLIC_LIST',
  ARTICLE_OPTION_TYPE_LIST = 'ARTICLE_OPTION_TYPE_LIST',
  ARTICLE_OPTION_LIST = 'ARTICLE_OPTION_LIST',
  ARTICLE_RANGE_USERTYPE_LIST = 'ARTICLE_RANGE_USERTYPE_LIST',
  ARTICLE_RANGE_LIST = 'ARTICLE_RANGE_LIST',
  ARTICLE_VIEW = 'ARTICLE_VIEW',
  ARTICLE_LIST = 'ARTICLE_LIST',
  ARTICLE_OPTION_PRICE_DELETE = 'ARTICLE_OPTION_PRICE_DELETE',
  ARTICLE_PRICE_DELETE = 'ARTICLE_PRICE_DELETE',

  CALENDARDAY_CREATE = 'CALENDARDAY_CREATE',
  CALENDARDAY_EDIT = 'CALENDARDAY_EDIT',
  CALENDARDAY_LIST = 'CALENDARDAY_LIST',

  CREDIT_CREATE = 'CREDIT_CREATE',
  CREDIT_USER_LIST = 'CREDIT_USER_LIST',

  DISCOUNTCODE_CREATE = 'DISCOUNTCODE_CREATE',
  DISCOUNTCODE_ISVALID = 'DISCOUNTCODE_ISVALID',
  DISCOUNTCODE_LIST = 'DISCOUNTCODE_LIST',

  DOCS_ARTICLETRACKING_TYPE = 'DOCS_ARTICLETRACKING_TYPE',
  DOCS_TRACKINGSHEET_TYPE = 'DOCS_TRACKINGSHEET_TYPE',
  DOC_QRCODETOKEN_TYPE = 'DOC_QRCODETOKEN_TYPE',

  EVENTCONFIG_CREATE = 'EVENTCONFIG_CREATE',
  EVENTCONFIG_EDIT = 'EVENTCONFIG_EDIT',
  EVENTCONFIG_DELETE = 'EVENTCONFIG_DELETE',

  INCIDENT_ORDERLINE_CREATE = 'INCIDENT_ORDERLINE_CREATE',
  INCIDENT_COMMENT_CREATE = 'INCIDENT_COMMENT_CREATE',
  INCIDENT_IMAGES_CREATE = 'INCIDENT_IMAGES_CREATE',
  INCIDENT_STATUS_CREATE = 'INCIDENT_STATUS_CREATE',
  INCIDENT_TYPE_CREATE = 'INCIDENT_TYPE_CREATE',
  INCIDENT_CREATE = 'INCIDENT_CREATE',
  INCIDENT_TYPE_EDIT = 'INCIDENT_TYPE_EDIT',
  INCIDENT_EDIT = 'INCIDENT_EDIT',
  INCIDENT_DETAILS = 'INCIDENT_DETAILS',
  INCIDENT_IMAGE_VIEW = 'INCIDENT_IMAGE_VIEW',
  INCIDENT_MINE_LIST = 'INCIDENT_MINE_LIST',
  INCIDENT_TYPE_LIST = 'INCIDENT_TYPE_LIST',
  INCIDENT_LIST = 'INCIDENT_LIST',

  LAVANDIER_QRCODE_CREATE = 'LAVANDIER_QRCODE_CREATE',
  LAVANDIER_CREATE = 'LAVANDIER_CREATE',
  LAVANDIER_QRCODE_DISABLE = 'LAVANDIER_QRCODE_DISABLE',
  LAVANDIER_EDIT = 'LAVANDIER_EDIT',
  LAVANDIER_DETAILS = 'LAVANDIER_DETAILS',
  LAVANDIER_LIST = 'LAVANDIER_LIST',

  LIGHT_EDIT = 'LIGHT_EDIT',
  LIGHT_DETAILS_LIST = 'LIGHT_DETAILS_LIST',
  LIGHT_BRIDGE_LIST = 'LIGHT_BRIDGE_LIST',
  LIGHT_LIST = 'LIGHT_LIST',

  MEMBERSHIPDISCOUNT_EDIT = 'MEMBERSHIPDISCOUNT_EDIT',
  MEMBERSHIPDISCOUNT_VIEW = 'MEMBERSHIPDISCOUNT_VIEW',
  MEMBERSHIPDISCOUNT_LIST = 'MEMBERSHIPDISCOUNT_LIST',

  MEMBERSHIP_LIST = 'MEMBERSHIP_LIST',

  NOTIFICATION_CREATE = 'NOTIFICATION_CREATE',

  ORDERARTICLETRACKING_REF_STORAGE_EDIT = 'ORDERARTICLETRACKING_REF_STORAGE_EDIT',
  ORDERARTICLETRACKING_SHOPSTORAGE_EDIT = 'ORDERARTICLETRACKING_SHOPSTORAGE_EDIT',
  ORDERARTICLETRACKING_UNTIDIED_EDIT = 'ORDERARTICLETRACKING_UNTIDIED_EDIT',
  ORDERARTICLETRACKING_FACTORY_EDIT = 'ORDERARTICLETRACKING_FACTORY_EDIT',
  ORDERARTICLETRACKING_DEFECT_CREATE = 'ORDERARTICLETRACKING_DEFECT_CREATE',
  ORDERARTICLETRACKING_TIDIED_EDIT = 'ORDERARTICLETRACKING_TIDIED_EDIT',
  ORDERARTICLETRACKING_REF_DETAILS = 'ORDERARTICLETRACKING_REF_DETAILS',

  ORDERBAG_NOTFOUND = 'ORDERBAG_NOTFOUND',
  ORDERBAG_ORDER_CREATE = 'ORDERBAG_ORDER_CREATE',
  ORDERBAG_ORDERARTICLE_CREATE = 'ORDERBAG_ORDERARTICLE_CREATE',
  ORDERBAG_STANDBY = 'ORDERBAG_STANDBY',
  ORDERBAG_FINISH = 'ORDERBAG_FINISH',
  ORDERBAG_CHECK = 'ORDERBAG_CHECK',
  ORDERBAG_EDIT = 'ORDERBAG_EDIT',
  ORDERBAG_DETAILS = 'ORDERBAG_DETAILS',
  ORDERBAG_ORDER_LIST = 'ORDERBAG_ORDER_LIST',
  ORDERBAG_REF_DETAILS = 'ORDERBAG_REF_DETAILS',
  ORDERBAG_REF_VIEW = 'ORDERBAG_REF_VIEW',
  ORDERBAG_ORDERARTICLE_DELETE = 'ORDERBAG_ORDERARTICLE_DELETE',

  ORDER_ORDERWANTEDDELIVERY_CREATE = 'ORDER_ORDERWANTEDDELIVERY_CREATE',
  ORDER_DELIVERY_CREATE = 'ORDER_DELIVERY_CREATE',
  ORDER_PAY_CREATE = 'ORDER_PAY_CREATE',
  ORDER_SEARCH = 'ORDER_SEARCH',
  ORDER_CREATE = 'ORDER_CREATE',
  ORDER_TRANSFER = 'ORDER_TRANSFER',
  ORDER_CANCEL_EDIT = 'ORDER_CANCEL_EDIT',
  ORDER_FINISH = 'ORDER_FINISH',
  ORDER_EDIT = 'ORDER_EDIT',
  ORDER_ORDERARTICLETRACKING_LIST = 'ORDER_ORDERARTICLETRACKING_LIST',
  ORDER_DETAILS = 'ORDER_DETAILS',
  ORDER_USER_LIST = 'ORDER_USER_LIST',
  ORDER_PIN_DETAILS = 'ORDER_PIN_DETAILS',
  ORDER_LIST = 'ORDER_LIST',

  ORDERTRANSPORT_CREATE = 'ORDERTRANSPORT_CREATE',
  ORDERTRANSPORT_REOPEN = 'ORDERTRANSPORT_REOPEN',
  ORDERTRANSPORT_DETAILS = 'ORDERTRANSPORT_DETAILS',
  ORDERTRANSPORT_LIST = 'ORDERTRANSPORT_LIST',

  PDF_INVOICE = 'PDF_INVOICE',

  ROLE_CREATE = 'ROLE_CREATE',
  ROLE_EDIT = 'ROLE_EDIT',
  ROLE_VIEW = 'ROLE_VIEW',
  ROLE_LIST = 'ROLE_LIST',

  SHIPPINGPRICE_CREATE = 'SHIPPINGPRICE_CREATE',
  SHIPPINGPRICE_EDIT = 'SHIPPINGPRICE_EDIT',
  SHIPPINGPRICE_DELETE = 'SHIPPINGPRICE_DELETE',

  SHOP_PERIMETER_CREATE = 'SHOP_PERIMETER_CREATE',
  SHOP_CREATE = 'SHOP_CREATE',
  SHOP_HOURS_EDIT = 'SHOP_HOURS_EDIT',
  SHOP_VIEW = 'SHOP_VIEW',
  SHOP_LIST = 'SHOP_LIST',
  SHOP_PERIMETER_DELETE = 'SHOP_PERIMETER_DELETE',

  SHOPSTORAGE_REF_VIEW = 'SHOPSTORAGE_REF_VIEW',

  SPONSORSHIP_ISVALID = 'SPONSORSHIP_ISVALID',

  STORAGE_USED_LIST = 'STORAGE_USED_LIST',
  STORAGE_LIST = 'STORAGE_LIST',

  TIMESLOT_CREATE = 'TIMESLOT_CREATE',
  TIMESLOT_TYPE_EDIT = 'TIMESLOT_TYPE_EDIT',
  TIMESLOT_TYPE_LIST = 'TIMESLOT_TYPE_LIST',
  TIMESLOT_DELETE = 'TIMESLOT_DELETE',

  TRACKINGSHEET_DETAILS = 'TRACKINGSHEET_DETAILS',
  TRACKINGSHEET_DETAILS_LIST = 'TRACKINGSHEET_DETAILS_LIST',
  TRACKINGSHEET_ORDER_LIST = 'TRACKINGSHEET_ORDER_LIST',
  TRACKINGSHEET_VIEW = 'TRACKINGSHEET_VIEW',

  TRACKINGSHEETSTORAGE_TIDIED = 'TRACKINGSHEETSTORAGE_TIDIED',
  TRACKINGSHEETSTORAGE_PREPARED = 'TRACKINGSHEETSTORAGE_PREPARED',
  TRACKINGSHEETSTORAGE_STORAGE = 'TRACKINGSHEETSTORAGE_STORAGE',

  TRANSPORTSCHEDULE_CREATE = 'TRANSPORTSCHEDULE_CREATE',
  TRANSPORTSCHEDULE_EDIT = 'TRANSPORTSCHEDULE_EDIT',
  TRANSPORTSCHEDULE_DELETE = 'TRANSPORTSCHEDULE_DELETE',

  UPLOAD_MULTIPLE_CREATE = 'UPLOAD_MULTIPLE_CREATE',
  UPLOAD_CREATE = 'UPLOAD_CREATE',
  UPLOAD_VIEW = 'UPLOAD_VIEW',

  USER_TYPE_PRICE_CREATE = 'USER_TYPE_PRICE_CREATE',
  USER_TYPE_CREATE = 'USER_TYPE_CREATE',
  USER_CREATE = 'USER_CREATE',
  USER_TYPE_PRICE_EDIT = 'USER_TYPE_PRICE_EDIT',
  USER_TYPE_EDIT = 'USER_TYPE_EDIT',
  USER_ENABLED = 'USER_ENABLED',
  USER_EDIT = 'USER_EDIT',
  USER_TIMESLOT_LIST = 'USER_TIMESLOT_LIST',
  USER_ADDRESS_LIST = 'USER_ADDRESS_LIST',
  USER_TYPE_DETAILS_LIST = 'USER_TYPE_DETAILS_LIST',
  USER_TYPE_VIEW = 'USER_TYPE_VIEW',
  USER_TYPE_LIST = 'USER_TYPE_LIST',
  USER_VIEW = 'USER_VIEW',
  USER_LIST = 'USER_LIST',
  USER_TYPE_PRICE_DELETE = 'USER_TYPE_PRICE_DELETE',
}
