import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { EventConfigEditModalComponent } from '../../modals/event-config-edit-modal/event-config-edit-modal.component';
import { ShippingPriceEditModalComponent } from '../../modals/shipping-price-edit-modal/shipping-price-edit-modal.component';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-user-type-edit',
  templateUrl: './user-type-edit.component.html',
  styleUrls: ['./user-type-edit.component.scss']
})
export class UserTypeEditComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;

  public userTypeId: number;
  public userType = null;
  public userTypeMap = new Map();
  public userTypeEditForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private fb: FormBuilder,
    public router: Router,
    private modalService: NgbModal,
    private errorService: ErrorService,
  ) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(param => {
      this.userTypeId = parseInt(param.userTypeId, 10);
      this.resetData();
      this.loadData().subscribe();
    });
  }

  resetData() {
    this.userType = null;
    this.userTypeMap = new Map();
  }

  loadData(): Observable<any> {
    return forkJoin([
      this.apiLavandierService.getUserType(this.userTypeId),
      this.apiLavandierService.getUserTypeList(),
    ]).pipe(
      map(([userType, userTypeList]: [any, any[]]) => {
        this.userType = userType;
        this.userTypeMap = ListToMap.convert(userTypeList.filter(ut => ut.id !== userType.id));
        this.buildForm();
      })
    );
  }

  buildForm() {
    this.userTypeEditForm = this.fb.group({
      parentUserTypeId: [this.userType.parentUserTypeId !== null ? this.userType.parentUserTypeId : null],
      name: [this.userType.name, Validators.required],
      sponsorship: [this.userType.sponsorship, Validators.required],
      fakePayment: [this.userType.fakePayment, Validators.required],
      important: [this.userType.important, Validators.required],
      noShowException: [this.userType.noShowException, Validators.required],
      noShowPrice: [this.userType.noShowPrice / PRICEMULTIPLIER, Validators.required],
      storageException: [this.userType.storageException, Validators.required],
      storagePrice: [this.userType.storagePrice / PRICEMULTIPLIER, Validators.required],
      storageDeadLine: [this.userType.storageDeadLine, Validators.required],
      storageDeadLineEvent1: [this.userType.storageDeadLineEvent1, Validators.required],
      storageDeadLineEvent2: [this.userType.storageDeadLineEvent2, Validators.required],
      sponsorshipGodfatherDiscount: [this.userType.sponsorshipGodfatherDiscount / PRICEMULTIPLIER, Validators.required],
      sponsorshipGodsonDiscount: [this.userType.sponsorshipGodsonDiscount / PRICEMULTIPLIER, Validators.required],
      sponsorshipMinOrder: [this.userType.sponsorshipMinOrder / PRICEMULTIPLIER, Validators.required],
    });
  }

  onValidateUserType() {
    this.apiLavandierService.putUserType(this.userType.id, {
      parentUserTypeId: this.userTypeEditForm.value.parentUserTypeId !== '' ? this.userTypeEditForm.value.parentUserTypeId : null,
      name: this.userTypeEditForm.value.name,
      important: this.userTypeEditForm.value.important,
      sponsorship: this.userTypeEditForm.value.sponsorship,
      fakePayment: this.userTypeEditForm.value.fakePayment,
      noShowException: this.userTypeEditForm.value.noShowException,
      noShowPrice: this.userTypeEditForm.value.noShowPrice * PRICEMULTIPLIER,
      storageException: this.userTypeEditForm.value.storageException,
      storagePrice: this.userTypeEditForm.value.storagePrice * PRICEMULTIPLIER,
      storageDeadLine: this.userTypeEditForm.value.storageDeadLine,
      storageDeadLineEvent1: this.userTypeEditForm.value.storageDeadLineEvent1,
      storageDeadLineEvent2: this.userTypeEditForm.value.storageDeadLineEvent2,
      sponsorshipGodfatherDiscount: this.userTypeEditForm.value.sponsorshipGodfatherDiscount * PRICEMULTIPLIER,
      sponsorshipGodsonDiscount: this.userTypeEditForm.value.sponsorshipGodsonDiscount * PRICEMULTIPLIER,
      sponsorshipMinOrder: this.userTypeEditForm.value.sponsorshipMinOrder * PRICEMULTIPLIER,
    })
      .subscribe(((data) => {
        if (!this.errorService.manageError(data)) {
          this.ngOnInit();
        }
      }));
  }

  openShippingPrice(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.openShippingPriceModal(event.row);
    }
  }

  openShippingPriceModal(shippingPrice?) {
    const shippingPriceEditModal = this.modalService.open(ShippingPriceEditModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    shippingPriceEditModal.componentInstance.userTypeId = this.userTypeId;
    shippingPriceEditModal.componentInstance.shippingPrice = shippingPrice;
    shippingPriceEditModal.result
      .then(() => this.ngOnInit())
      .catch(() => null);
  }

  openEventConfig(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      this.openEventConfigModal(event.row);
    }
  }

  openEventConfigModal(eventConfig?) {
    const eventConfigEditModal = this.modalService.open(EventConfigEditModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    eventConfigEditModal.componentInstance.configId = this.userType.configId;
    eventConfigEditModal.componentInstance.eventConfig = eventConfig;
    eventConfigEditModal.result
      .then(() => this.ngOnInit())
      .catch(() => null);
  }

  openAccount(event) {
    if (event.type === 'click') {
      this.router.navigate(['account/edit', event.row.id]);
    }
  }
}
