import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

import { OrderTransportType } from '../../../../lib/lib-shared/types/OrderTransportType';
import { ListToMap } from '../../../../lib/lib-ngx/utils/ListToMap';
import { OrderAddressType } from '../../../../lib/lib-shared/types/OrderAddressType';
import { ConfirmationModalComponent } from '../../modals/confirmation-modal/confirmation-modal.component';
import { ApiLavandierService } from '../../../../lib/lib-ngx/web-services/api-lavandier.service';
import {
  UserProfileSendMessageModalComponent
} from '../../modals/user-profile-send-message-modal/user-profile-send-message-modal.component';
import { ErrorService } from '../../../../lib/lib-ngx/services/error.service';
import { UserDataVerificationModalComponent } from '../../modals/user-data-verification-modal/user-data-verification-modal.component';
import { AddPaymentModalComponent } from '../../modals/add-payment-modal/add-payment-modal.component';
import { ErrorMessage } from '../../../../lib/lib-shared/ErrorMessage';
import { PaymentStatus } from '../../../../lib/lib-shared/types/PaymentStatus';
import { OrderTemporary } from '../../../../lib/lib-shared/types/OrderTemporary';
import { OrderArticleTrackingDefectOriginMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectOrigin';
import { OrderArticleTrackingDefectStationMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectStation';
import { OrderArticleTrackingDefectTypeMap } from '../../../../lib/lib-ngx/web-services/TypeMap/OrderArticleTrackingDefectType';
import { ImagePreviewModalComponent } from '../../modals/image-preview-modal/image-preview-modal.component';
import { OrderStatusStatus } from '../../../../lib/lib-shared/types/OrderStatusStatus';
import { PRICEMULTIPLIER } from '../../../../lib/lib-shared/defines';

@Component({
  selector: 'lm-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  public PRICEMULTIPLIER = PRICEMULTIPLIER;
  public OrderAddressType = OrderAddressType;
  public OrderStatusStatus = OrderStatusStatus;
  public OrderTransportType = OrderTransportType;
  public OrderTemporary = OrderTemporary;
  public OrderArticleTrackingDefectOriginMap = OrderArticleTrackingDefectOriginMap;
  public OrderArticleTrackingDefectStationMap = OrderArticleTrackingDefectStationMap;
  public OrderArticleTrackingDefectTypeMap = OrderArticleTrackingDefectTypeMap;

  @ViewChild('articleTable') articleTable: any;
  @ViewChild('articleByBagTable') articleByBagTable: any;
  public orderId = null;
  public order = null;

  public lavandierMap = new Map();
  public scheduleDelivery = false;
  public userTypeMap = new Map();
  public eventList = [];
  public incidentTypeMap = new Map();
  public orderTransportHistoryList = [];
  public storageMap = new Map();
  public shopMap = new Map();

  public userAddressList = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiLavandierService: ApiLavandierService,
    private modalService: NgbModal,
    private errorService: ErrorService,
    private router: Router,
    public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.scheduleDelivery = false;

    this.activatedRoute.params.subscribe(
      (params: any) => {
        this.orderId = params.id;
        this.loadData();
      }
    );
  }

  loadData() {
    return forkJoin([
      this.apiLavandierService.getOrderIdDetails(this.orderId),
      this.apiLavandierService.getLavandiers(),
      this.apiLavandierService.getUserTypeList(),
      this.apiLavandierService.getIncidentList({orderId: this.orderId}),
      this.apiLavandierService.getIncidentTypes(),
      this.apiLavandierService.getStorageList(),
      this.apiLavandierService.getShops(),
    ])
      .pipe(
        map(([order, lavandierList, userTypeList, eventList, incidentTypeList, storageList, shopList]: any[]) => {
          return [
            order,
            ListToMap.convert(lavandierList.rows as any[]),
            ListToMap.convert(userTypeList as any[]),
            eventList,
            ListToMap.convert(incidentTypeList as any[]),
            ListToMap.convert((storageList as any[])),
            ListToMap.convert(((shopList as any[])))
          ];
        })
      )
      .subscribe(([order, lavandierMap, userTypeMap, eventList, incidentTypeMap, storageMap, shopMap]) => {
        this.order = order;
        this.lavandierMap = lavandierMap as any;
        this.userTypeMap = userTypeMap as any;
        this.eventList = (eventList as any).rows;
        this.incidentTypeMap = incidentTypeMap as any;
        this.storageMap = storageMap as any;
        this.shopMap = shopMap as any;
        this.loadUserAddressList();
        this.initOrderTransportHistoryList();
      });
  }

  getOrderArticleList() {
    const orderArticleList = [];
    this.order.orderBags.forEach(orderBag => {
      orderBag.orderArticles.map(orderArticle => {
        const bag = Object.assign({}, orderBag);
        delete bag.orderArticles;
        orderArticle.orderBag = bag;
      });
      orderArticleList.push(...orderBag.orderArticles);
    });
    return orderArticleList;
  }

  onAddPayment() {
    if (this.order.paymentList.some(payment => payment.status === PaymentStatus.PROCESSING)) {
      return this.errorService.showModal(ErrorMessage.ORDER_PAYMENT_PROCESSING);
    }
    const addPaymentModalComponent = this.modalService.open(AddPaymentModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
      keyboard: false
    });
    addPaymentModalComponent.result
      .then((payment) => this.apiLavandierService.postOrderIdPay(this.order.id, payment)
        .subscribe(data => {
          if (!this.errorService.manageError(data)) {
            this.ngOnInit();
          }
        }))
      .catch(() => null);
  }

  getLastAddressByType(type: OrderAddressType) {
    const orderAddressListOfType = this.order.orderAddresses.filter(orderAddress => orderAddress.type === type);
    return orderAddressListOfType[orderAddressListOfType.length - 1];
  }

  loadUserAddressList() {
    this.apiLavandierService.getUserAddressList(this.order.userId)
      .subscribe(
        (userAddressList: any[]) => {
          this.userAddressList = userAddressList;
        }
      );
  }

  initOrderTransportHistoryList() {
    this.order.orderTransports.forEach((orderTransport) => {
      this.orderTransportHistoryList.push({
        createdAt: orderTransport.createdAt,
        title: orderTransport.type + 'CREATED',
        orderTransport: null,
      });
      if (orderTransport.startedAt !== null) {
        this.orderTransportHistoryList.push({
          createdAt: orderTransport.startedAt,
          title: orderTransport.type + 'STARTED',
          orderTransport: null,
        });
      }
      if (orderTransport.canceledAt !== null) {
        this.orderTransportHistoryList.push({
          createdAt: orderTransport.canceledAt,
          title: orderTransport.type + 'CANCELED',
          orderTransport: null,
        });
      }
      if (orderTransport.doneAt !== null) {
        this.orderTransportHistoryList.push({
          createdAt: orderTransport.doneAt,
          title: orderTransport.type + 'DONE',
          orderTransport: orderTransport,
        });
      }
    });
  }

  loadInvoice() {
    this.apiLavandierService.getPdfInvoice(this.order.id)
      .subscribe((blob: Blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          try {
            this.errorService.manageError(JSON.parse(reader.result));
          } catch (e) {
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.target = '_blank';
            a.download = 'facture-commande-' + this.order.id;
            a.click();
            window.URL.revokeObjectURL(a.href);
          }
        };
        reader.readAsText(blob);
      });
  }

  onAddDelivery(orderTransportData) {
    this.apiLavandierService.postOrderIdDelivery(this.orderId, {
      address: orderTransportData.selectedAddress,
      shopId: orderTransportData.shopId,
      date: orderTransportData.date,
      start: orderTransportData.start,
      end: orderTransportData.end,
    })
      .subscribe(data => {
        const confirmModal = this.modalService.open(ConfirmationModalComponent);
        const orderUserFullName = new TitleCasePipe().transform(this.order.user.firstName + ' ' + this.order.user.lastName);
        confirmModal.componentInstance.confirmationDesc = 'Confirmation de la création d\'une livraison pour la commande numéro ' +
          this.orderId + ' pour ' + orderUserFullName;
        confirmModal.componentInstance.validateButtonText = 'Ok';
        this.ngOnInit();
      });
  }

  cancelOrderTransport() {
    this.apiLavandierService.putOrderCancel(this.orderId)
      .subscribe((data: any) => {
        if (!this.errorService.manageError(data)) {
          this.ngOnInit();
        }
      });
  }

  getLastOrderTransportDeliveryIfValid(type?: OrderTransportType) {
    let orderTransport = null;
    for (let i = this.order.orderTransports.length - 1; i >= 0; i--) {
      if (!type || this.order.orderTransports[i].type === type) {
        orderTransport = this.order.orderTransports[i];
        break;
      }
    }

    if (orderTransport && !orderTransport.doneAt && !orderTransport.canceledAt) {
      return orderTransport;
    }
    return null;
  }

  openModal() {
    const modal = this.modalService.open(UserProfileSendMessageModalComponent, {size: 'lg'});
    modal.componentInstance.user = this.order.user;
  }

  onCounterDelivery() {
    const userDataVerificationModal = this.modalService.open(UserDataVerificationModalComponent, {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    });
    userDataVerificationModal.componentInstance.userDetails = this.order.user;
    userDataVerificationModal.componentInstance.userAddresses = this.order.orderAddresses;
    userDataVerificationModal.result
      .then(() => this.router.navigate(['/quarterbacking/order', this.order.id]))
      .catch(() => null);
  }

  getOrderArticleTrackingDefectList(): any[] {
    const orderArticleTrackingDefectList = [];

    for (const orderBag of this.order.orderBags) {
      for (const orderArticle of orderBag.orderArticles) {
        for (const orderArticleTracking of orderArticle.orderArticleTrackingList) {
          for (const orderArticleTrackingDefect of orderArticleTracking.orderArticleTrackingDefectList) {
            orderArticleTrackingDefectList.push({
              ...orderArticleTrackingDefect, ...{
                parentId: orderArticleTrackingDefect.parentId ? orderArticleTrackingDefect.parentId : orderArticleTrackingDefect.id,
                orderArticleTracking: {...orderArticleTracking, ...{orderArticle: orderArticle}}
              }
            });
          }
        }
      }
    }

    return orderArticleTrackingDefectList;
  }

  openImagePreviewModal(event) {
    if (event.type === 'click') {
      event.cellElement.blur();
      const orderArticleTrackingDefect = event.row;
      const imagePreviewModal = this.modalService.open(ImagePreviewModalComponent, {size: 'lg'});
      imagePreviewModal.componentInstance.imageList = [orderArticleTrackingDefect];
      imagePreviewModal.componentInstance.selectedImage = orderArticleTrackingDefect;
    }
  }

  onManuallyFinish() {
    this.apiLavandierService.putOrderIdFinish(this.orderId).subscribe(data => {
      if (!this.errorService.manageError(data)) {
        this.ngOnInit();
      }
    });
  }
}
