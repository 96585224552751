export enum ErrorMessage {
  ORDER_ARTICLETRACKING_REF_NOTFOUND = 'ORDER_ARTICLETRACKING_REF_NOTFOUND',
  ORDER_ARTICLETRACKING_PIN_NOTFOUND = 'ORDER_ARTICLETRACKING_PIN_NOTFOUND',
  ORDER_ARTICLETRACKING_ALREADY_TIDIED = 'ORDER_ARTICLETRACKING_ALREADY_TIDIED',
  NO_STORAGE_AVAILABLE = 'NO_STORAGE_AVAILABLE',
  DELIVERY_ALREADY_ASKED = 'DELIVERY_ALREADY_ASKED',
  SLOT_NOT_AVAILABLE_DATE = 'SLOT_NOT_AVAILABLE_DATE',
  SLOT_NOT_AVAILABLE_TIME = 'SLOT_NOT_AVAILABLE_TIME',
  NO_TRANSPORT_TO_CANCEL = 'NO_TRANSPORT_TO_CANCEL',
  PICKUPINSHOP_IS_NOT_CANCELABLE = 'PICKUPINSHOP_IS_NOT_CANCELABLE',
  TOO_LATE_TO_CANCEL_TRANSPORT = 'TOO_LATE_TO_CANCEL_TRANSPORT',
  INCORRECT_CREDENTIAL = 'INCORRECT_CREDENTIAL',
  INVALID_TOKEN = 'INVALID_TOKEN',
  ACCOUNT_DISABLED = 'ACCOUNT_DISABLED',
  ORDER_ALREADY_PAID = 'ORDER_ALREADY_PAID',
  ORDER_ALREADY_FINISH = 'ORDER_ALREADY_FINISH',
  ORDER_NOT_PAYABLE = 'ORDER_NOT_PAYABLE',
  NOT_A_DELIVERABLE_ORDER = 'NOT_A_DELIVERABLE_ORDER',
  EMAIL_ALREADY_USED = 'EMAIL_ALREADY_USED',
  ORDERTRANSPORT_NOT_QUARTERBACKABLE = 'ORDERTRANSPORT_NOT_QUARTERBACKABLE',
  NOT_ALLOWED = 'NOT_ALLOWED',
  ORDER_ARTICLETRACKING_WRONG_FLUX = 'ORDER_ARTICLETRACKING_WRONG_FLUX',
  NOT_MANAGED_ADDRESS = 'NOT_MANAGED_ADDRESS',
  ORDER_ARTICLETRACKING_IS_NOT_UNTIDIED = 'ORDER_ARTICLETRACKING_IS_NOT_UNTIDIED',
  ORDERBAG_ALREADY_FINISH = 'ORDERBAG_ALREADY_FINISH',
  INVOICE_NOT_AVAILABLE = 'INVOICE_NOT_AVAILABLE',
  ORDERTRANSPORT_NOT_REOPENABLE = 'ORDERTRANSPORT_NOT_REOPENABLE',
  ORDER_ARTICLETRACKING_REF_ALREADY_EXIST = 'ORDER_ARTICLETRACKING_REF_ALREADY_EXIST',
  INCORRECT_VALUE = 'INCORRECT_VALUE',
  WRONG_PHONE_CODE = 'WRONG_PHONE_CODE',
  TRACKINGSHEET_NOT_TIDIABLE = 'TRACKINGSHEET_NOT_TIDIABLE',
  INCORRECT_ENDPOINT = 'INCORRECT_ENDPOINT',
  ORDER_PAYMENT_PROCESSING = 'ORDER_PAYMENT_PROCESSING',
  TRACKINGSHEET_NOT_FACTORY_CHECKED = 'TRACKINGSHEET_NOT_FACTORY_CHECKED',
  ORDER_NOT_AFTERSALE = 'ORDER_NOT_AFTERSALE',
  ORDER_NOT_TEMPORARY = 'ORDER_NOT_TEMPORARY',
  ORDERBAG_REF_ALREADY_EXIST = 'ORDERBAG_REF_ALREADY_EXIST',
  NO_DELIVERYMULTIPLE_AVAILABLE = 'NO_DELIVERYMULTIPLE_AVAILABLE',
  ORDER_NOT_TRANSFERABLE = 'ORDER_NOT_TRANSFERABLE',
  ORDERBAG_ALREADY_CHECKED = 'ORDERBAG_ALREADY_CHECKED',
  ORDER_NOT_PICKUP_MULTIPLE = 'ORDER_NOT_PICKUP_MULTIPLE',
  ORDERBAG_NOT_CHECKED = 'ORDERBAG_NOT_CHECKED',
  TRACKINGSHEET_NOT_TIDIED = 'TRACKINGSHEET_NOT_TIDIED',
  ORDER_NOT_PAID = 'ORDER_NOT_PAID',
  INCIDENT_ALREADY_FINISH = 'INCIDENT_ALREADY_FINISH',
  ORDER_ARTICLETRACKING_ORDER_INCIDENT = 'ORDER_ARTICLETRACKING_ORDER_INCIDENT',
  DELIVERY_ADDRESS_NOT_IN_THE_SAME_PERIMETER = 'DELIVERY_ADDRESS_NOT_IN_THE_SAME_PERIMETER',
  ORDER_ARTICLETRACKING_ALREADY_FACTORY = 'ORDER_ARTICLETRACKING_ALREADY_FACTORY',
  ORDERBAG_IS_LAST_NOT_FINISH_OF_EMPTY_ORDER = 'ORDERBAG_IS_LAST_NOT_FINISH_OF_EMPTY_ORDER',
  STRIPE = 'STRIPE',
  TRANSPORTSHEET_REF_NOTFOUND = 'TRANSPORTSHEET_REF_NOTFOUND',
  TRANSPORTSHEET_ALREADY_YOURS = 'TRANSPORTSHEET_ALREADY_YOURS',
  TRANSPORTSHEET_ALREADY_USED = 'TRANSPORTSHEET_ALREADY_USED',
}
